import { useMemo } from 'react'
import { Carrier } from '../entities/carrier'

export function useAllCarriers(): { [iata: string]: Carrier } {
  return useMemo(() => {
    return {
      '5J': { iata: '5J', name: 'Cebu Pacific Air' } as Carrier,
      '6E': { iata: '6E', name: 'IndiGo' } as Carrier,
      '8L': { iata: '8L', name: 'Lucky Air' } as Carrier,
      '9C': { iata: '9C', name: 'Spring Airlines' } as Carrier,
      '9E': { iata: '9E', name: 'Endeavor Air' } as Carrier,
      'DP*': { iata: 'DP*', name: 'Pobeda' } as Carrier,
      A3: { iata: 'A3', name: 'Aegean Airlines' } as Carrier,
      AC: { iata: 'AC', name: 'Air Canada' } as Carrier,
      AD: { iata: 'AD', name: 'Azul' } as Carrier,
      AF: { iata: 'AF', name: 'Air France' } as Carrier,
      AI: { iata: 'AI', name: 'Air India' } as Carrier,
      AK: { iata: 'AK', name: 'AirAsia' } as Carrier,
      AY: { iata: 'AY', name: 'Finnair' } as Carrier,
      B6: { iata: 'B6', name: 'JetBlue Airways' } as Carrier,
      BA: { iata: 'BA', name: 'British Airways' } as Carrier,
      BR: { iata: 'BR', name: 'EVA Air' } as Carrier,
      CI: { iata: 'CI', name: 'China Airlines' } as Carrier,
      CM: { iata: 'CM', name: 'Copa Airlines' } as Carrier,
      CV: { iata: 'CV', name: 'Cargolux' } as Carrier,
      CX: { iata: 'CX', name: 'Cathay Pacific' } as Carrier,
      DE: { iata: 'DE', name: 'Condor' } as Carrier,
      DL: { iata: 'DL', name: 'Delta Air Lines' } as Carrier,
      EI: { iata: 'EI', name: 'Aer Lingus' } as Carrier,
      EK: { iata: 'EK', name: 'Emirates' } as Carrier,
      ET: { iata: 'ET', name: 'Ethiopian Airlines' } as Carrier,
      EW: { iata: 'EW', name: 'Eurowings' } as Carrier,
      EY: { iata: 'EY', name: 'Etihad Airways' } as Carrier,
      F9: { iata: 'F9', name: 'Frontier Airlines' } as Carrier,
      FD: { iata: 'FD', name: 'Thai AirAsia' } as Carrier,
      FM: { iata: 'FM', name: 'Shanghai Airlines' } as Carrier,
      FR: { iata: 'FR', name: 'Ryanair' } as Carrier,
      FX: { iata: 'FX', name: 'FedEx' } as Carrier,
      FZ: { iata: 'FZ', name: 'Flydubai' } as Carrier,
      G3: { iata: 'G3', name: 'Gol' } as Carrier,
      G4: { iata: 'G4', name: 'Allegiant Air' } as Carrier,
      HA: { iata: 'HA', name: 'Hawaiian Airlines' } as Carrier,
      HO: { iata: 'HO', name: 'Juneyao Airlines' } as Carrier,
      HU: { iata: 'HU', name: 'Hainan Airlines' } as Carrier,
      IB: { iata: 'IB', name: 'Iberia' } as Carrier,
      JIA: { iata: 'JIA', name: 'PSA Airlines' } as Carrier,
      JQ: { iata: 'JQ', name: 'Jetstar' } as Carrier,
      KE: { iata: 'KE', name: 'Korean Air' } as Carrier,
      KL: { iata: 'KL', name: 'KLM' } as Carrier,
      LH: { iata: 'LH', name: 'Lufthansa' } as Carrier,
      LO: { iata: 'LO', name: 'LOT - Polish Airlines' } as Carrier,
      LS: { iata: 'LS', name: 'Jet2' } as Carrier,
      LX: { iata: 'LX', name: 'Swiss' } as Carrier,
      LY: { iata: 'LY', name: 'El Al' } as Carrier,
      MF: { iata: 'MF', name: 'Xiamen Airlines' } as Carrier,
      MH: { iata: 'MH', name: 'Malaysia Airlines' } as Carrier,
      MI: { iata: 'MI', name: 'SilkAir' } as Carrier,
      MQ: { iata: 'MQ', name: 'Envoy Air' } as Carrier,
      MU: { iata: 'MU', name: 'China Eastern Airlines' } as Carrier,
      NK: { iata: 'NK', name: 'Spirit Airlines' } as Carrier,
      OS: { iata: 'OS', name: 'Austrian' } as Carrier,
      OZ: { iata: 'OZ', name: 'Asiana Airlines' } as Carrier,
      PC: { iata: 'PC', name: 'Pegasus' } as Carrier,
      PO: { iata: 'PO', name: 'Polar Air Cargo' } as Carrier,
      PR: { iata: 'PR', name: 'Philippine Airlines' } as Carrier,
      QX: { iata: 'QX', name: 'Horizon Air' } as Carrier,
      QR: { iata: 'QR', name: 'Qatar Airways' } as Carrier,
      QZ: { iata: 'QZ', name: 'Indonesia AirAsia' } as Carrier,
      RU: { iata: 'RU', name: 'AirBridgeCargo' } as Carrier,
      SC: { iata: 'SC', name: 'Shandong Airlines' } as Carrier,
      SG: { iata: 'SG', name: 'SpiceJet' } as Carrier,
      SQ: { iata: 'SQ', name: 'Singapore Airlines' } as Carrier,
      TG: { iata: 'TG', name: 'Thai Airways International' } as Carrier,
      TK: { iata: 'TK', name: 'Turkish Airlines' } as Carrier,
      TP: { iata: 'TP', name: 'TAP Air Portugal' } as Carrier,
      TR: { iata: 'TR', name: 'Scoot' } as Carrier,
      TS: { iata: 'TS', name: 'Air Transat' } as Carrier,
      TT: { iata: 'TT', name: 'Tigerair Australia' } as Carrier,
      U2: { iata: 'U2', name: 'EasyJet' } as Carrier,
      U6: { iata: 'U6', name: 'Ural Airlines' } as Carrier,
      VA: { iata: 'VA', name: 'Virgin Australia' } as Carrier,
      VJC: { iata: 'VJC', name: 'VietJet Air' } as Carrier,
      VN: { iata: 'VN', name: 'Vietnam Airlines' } as Carrier,
      W6: { iata: 'W6', name: 'Wizz Air' } as Carrier,
      WN: { iata: 'WN', name: 'Southwest Airlines' } as Carrier,
      WS: { iata: 'WS', name: 'WestJet' } as Carrier,
      XQ: { iata: 'XQ', name: 'SunExpress' } as Carrier,
      Y4: { iata: 'Y4', name: 'Volaris' } as Carrier,
      Z2: { iata: 'Z2', name: 'Philippines AirAsia' } as Carrier,
      ZH: { iata: 'ZH', name: 'Shenzhen Airlines' } as Carrier
    }
  }, [])
}
